import React, { useState, useEffect } from "react";
import { usePersonalData } from "../components/Quiz/Provider";
import QuizIntro from "../components/QuizIntro";
import QuizImage from "../assets/images/quiz-img.png";

const QuizPage = () => {
  const [quizUrl, setUrlParams] = useState('/quiz/intro');
	const userPersonalData = usePersonalData();
	const currentQuizId = userPersonalData.quizName;

  useEffect(() => {
    if (currentQuizId && currentQuizId !== 'quiz-1') {
      setUrlParams(`/${currentQuizId}/intro`);
		}
  }, []);

  return (
    <QuizIntro
      title="CASA self-assessment questionnaire"
      text="Fast, easy, and effective way to analyze your app’s current security posture."
      link={{
        url: quizUrl,
        label: "Let’s start"
      }}
      image={QuizImage}
      list={[
        { title: "Consists of 28 questions" },
        { title: "Takes 15 minutes to complete" },
        { title: "For each control, please select an answer that fits best" },
        { title: "Assessment results in 24-48 hours" },
        { title: "If you have any questions, contact us via email <a href='mailto:casa@leviathansecurity.com'>casa@leviathansecurity.com</a>"}
      ]}
    />
  );
}

export default QuizPage;
