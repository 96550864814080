import React, { FunctionComponent } from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import FooterQuiz from "../FooterQuiz";
import Logo from "../../assets/images/casa-logo.svg";
import "./QuizIntro.scss";

interface QuizIntroProps {
  title: string;
  text: string;
  link: {
    url: string;
    label: string;
  };
  image: string;
  list: Array<{
    title: string;
  }>;
}

const QuizIntro: FunctionComponent<QuizIntroProps> = props => {
  const {
    title,
    text,
    link,
    image,
    list
  } = props;

  return (
    <section className="quiz-intro">
      <div className="quiz-intro__inner">
        <div className="container">
          <div className="quiz-intro__body">
            <div className="quiz-intro__content">
              <Link className="quiz-intro__logo" to="/">
                <img
                  className="quiz-intro__logo-image"
                  src={Logo}
                  alt="CASA by leviathan security group"
                  width="141"
                  height="26"
                />
              </Link>
              <div className="quiz-intro__head">
                <div className="quiz-intro__image quiz-intro__image_mobile">
                  {image && (
                    <img
                      className="quiz-intro__image-i"
                      src={image}
                      alt=""
                      width="452"
                      height="452"
                    />
                  )}
                </div>
                {title && (
                  <h1 className="quiz-intro__title">{title}</h1>
                )}
              </div>
              {text && (
                <div className="quiz-intro__text">{text}</div>
              )}
              {list && (
                <ul className="quiz-intro__list">
                  {list.map(item => (
                    <li className="quiz-intro__list-item" key={item.title}>
                      <div className="quiz-intro__list-icon">
                        <svg
                          className="quiz-intro__list-icon-i"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.00008 17.3334C4.39758 17.3334 0.666748 13.6026 0.666748 9.00008C0.666748 4.39758 4.39758 0.666748 9.00008 0.666748C13.6026 0.666748 17.3334 4.39758 17.3334 9.00008C17.3334 13.6026 13.6026 17.3334 9.00008 17.3334ZM8.16925 12.3334L14.0609 6.44091L12.8826 5.26258L8.16925 9.97675L5.81175 7.61925L4.63342 8.79758L8.16925 12.3334Z"
                            fill="#44D27D"
                          />
                        </svg>
                      </div>
                      {item.title && (
                        <div
                          className="quiz-intro__list-title"
                          dangerouslySetInnerHTML={{
                            __html: item.title,
                          }}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              )}
              <div className="quiz-intro__footer">
                {link && (
                  <Link
                    className={classNames("quiz-intro__button btn btn_default")}
                    to={link.url}
                  >
                    {link.label}
                    <span className="quiz-intro__button-caret-right" />
                  </Link>
                )}
              </div>
            </div>
            <div className="quiz-intro__image quiz-intro__image_main">
              {image && (
                <img
                  className="quiz-intro__image-i"
                  src={image}
                  alt=""
                  width="452"
                  height="452"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="quiz-intro__bottom">
        <FooterQuiz />
      </div>
    </section>
  );
};

export default QuizIntro;
